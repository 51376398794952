import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Start from '../versions/caleb/start'

class RootIndex extends React.Component {
  render() {
    const projects = get(this, 'props.data.allContentfulProject.nodes')
    return (
      <Start
        lang="it"
        {...{projects}}
      />
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulProject(
      sort: {fields: title, order: DESC}
      filter: {node_locale: {eq: "it-IT"}}
    ) {
      nodes {
        title
        slug
        year
        type
        genre
        hasPage
        poster {
          image {
            gatsbyImageData(placeholder: BLURRED, width: 1000)
          }
        }
        pitch {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
